import { customAxios } from "@/shared/config/axios-interceptor";

const PATH_DEVICE = '/vehicles/devices';

export default class DeviceService {
  public async fetchAllDevice(): Promise<any> {
    try {
      const res = await customAxios.get(`${PATH_DEVICE}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async addDevice(params): Promise<any> {
    try {
      const data = {
        device_id: params.device_id
      }
      const res = await customAxios.post(`${PATH_DEVICE}`,JSON.stringify(data));
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async deleteDevice(params): Promise<any> {
    try {
      const res = await customAxios.delete(`${PATH_DEVICE}?device_id=${params.device_id}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

