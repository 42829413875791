import { computed, ref } from "vue";
import { useStore } from '@/store';
import _ from 'lodash';

export const useTranslate = () => {
  const store = useStore();
  const currentLanguage = ref(localStorage.currentLanguage);
  const listDataTranslate = computed(() => store.translateList);
  const isUseRecordId = (listDataTranslate.value.length > 0 && listDataTranslate.value.some(record => !_.isEmpty(record.record_id)));

  const translateRoute = (data: object): string => {

    if (currentLanguage.value === 'ja') {
      return data['route_name'];
    }
    const translateTable = listDataTranslate.value.filter(record => record.table_name === 'routes' && record.field_name === data['route_name_source']);
    const translateRecord = isUseRecordId
        ? translateTable.find(record => data['route_id'] !== '' && record.record_id === data['route_id'])
        : translateTable.find(record => record.field_value === data['route_name']);

    return translateRecord?.translation || data['route_name'];
  }

  const translateHeadSign = (data: object): string => {
    if (currentLanguage.value === 'ja') {
      return data['jp_pattern_name'];
    }
    const translateTable = listDataTranslate.value.filter(record => record.table_name === 'trips');
    const translateRecord = isUseRecordId
        ? translateTable.find(record => data['trip_id'] !== '' && record.record_id === data['trip_id'])
        : translateTable.find(record => record.field_value === data['trip_headsign']);
    return translateRecord?.translation || data['jp_pattern_name'];
  }

  const translateStop = (data: object): string => {
    if (currentLanguage.value === 'ja') {
      return data['stop_name'];
    }
    const translateTable = listDataTranslate.value.filter(record => record.table_name === 'stops');
    const translateRecord = isUseRecordId
        ? translateTable.find(record => data['stop_id'] !== '' && record.record_id === data['stop_id'])
        : translateTable.find(record => record.field_value === data['stop_name']);

    return translateRecord?.translation || data['stop_name'];
    }

    /**
     * translateStopForEditRoute
     * @param data
     * @returns
     */
    const translateStopForEditRoute = (busTopListSelectBox: any): any => {
      let result = [];
      const translateTable = listDataTranslate.value.filter(record => record.table_name === 'stops');
      const listData = busTopListSelectBox?.value;
      const listDataClone = _.cloneDeep(listData);
      listData?.forEach(data => {
        if(data['location_type'] == "1") {
          if(currentLanguage.value === 'ja') {
            result.push(data);
          } else {
            const temp = isUseRecordId ?  translateTable.find(record => data.stop_id !== '' && record.record_id === data.stop_id)
            : translateTable.find(record => record.field_value === data['stop_name']);
            if(!temp) {
                const dataLocationType0 = listDataClone.filter(item => item.parent_station === data.stop_id);
                if(dataLocationType0 && dataLocationType0.length){
                  const translate = isUseRecordId ?
                  translateTable.find(record => dataLocationType0[0].stop_id !== '' && record.record_id === dataLocationType0[0].stop_id)
                  : translateTable.find(record => record.field_value === data['stop_name']);
                  data['stop_name'] = translate ? translate.translation : data['stop_name'];
                  result.push(data);
                } else {
                  result.push(data);
                }
            }
            else{
              data['stop_name'] = temp.translation;
              result.push(data);
            }
          }
        }
      })
      return result;
    }

  return {
    translateRoute,
    translateHeadSign,
    translateStop,
    translateStopForEditRoute
  };
};
