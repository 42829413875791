import { customAxios } from "@/shared/config/axios-interceptor";

const PATH_USER = '/users';

export default class UserService {
  public async fetchAllUser(): Promise<any> {
    try {
      const res = await customAxios.get(PATH_USER);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async deleteUserById(userId: string|number) : Promise<any> {
    try {
      const res = await customAxios.delete(`${PATH_USER}/${userId}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }

  public async unlockUser(userId: string|number) {
    try {
      const res = await customAxios.post(`${PATH_USER}/${userId}/unlock`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }

  public async addUser(params: {userId: string, userName: string, email: string, officeId: string, roleId: string, password: string}): Promise<any> {
    try {
      // list params
      const data = {
        user_id: params.userId,
        username: params.userName,
        email: params.email,
        office_id: params.officeId,
        role_id: params.roleId,
        password: params.password
      };
      const res = await customAxios.post(PATH_USER, JSON.stringify(data));
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }
  public async editUser(params: {userId: string, userName: string, email: string, officeId: string, roleId: string}): Promise<any> {
    try {
      // list params
      const data = {
        user_id: params.userId,
        username: params.userName,
        email: params.email,
        office_id: params.officeId,
        role_id: params.roleId,
      };
      const res = await customAxios.put(PATH_USER, JSON.stringify(data));
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async getPermissions(): Promise<any> {
    try {
      const res = await customAxios.get(`${PATH_USER}/permissions`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async setPermissions(params): Promise<any> {
    try {
      //list param
      const data = [
        {
          role_id: "0",
          permissions: params.general
        },
        {
          role_id: "1",
          permissions: params.manager
        },
      ]
      const res = await customAxios.put(`${PATH_USER}/permissions`, JSON.stringify(data));
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async getOffices(): Promise<any> {
    try {
      const res = await customAxios.get(`${PATH_USER}/offices`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

