import { computed, defineComponent, provide, ref, watch, onMounted,inject } from 'vue';
import { useI18n } from 'vue-i18n';
import Footer from '@/features/core/footer/footer.vue';
import Navbar from '@/features/core/navbar/navbar.vue';
import BtnGroup from '@/features/core/navbar/btnGroup/btn-group-schedule.vue';
import BtnGroupHistory from '@/features/core/navbar/btnGroupHistory/btn-history.vue';
import { useStore } from '@/store';
import { useAlertService } from '@/shared/alert/alert.service';
import SettingService from '@/services/setting/setting.service';
import { CONST } from '@/constants';


import '@/shared/config/dayjs';
import router from './router';
export default defineComponent({
  compatConfig: { MODE: 3 },
  name: 'App',
  components: {
    navbar: Navbar,
    footer: Footer,
    BtnGroup: BtnGroup,
    BtnGroupHistory
  },
  setup() {
    const settingService = inject('settingService', () => new SettingService(), true);
    const { logout, clearState } = Navbar.setup();
    const store = useStore();
    const errorRouterMes = ref('');
    const fromNamePath = ref();
    const isLoading = computed(() => {
      return store.isLoading;
    });
    const isShowBtnGroup = ref(false);
    const isShowBtnGroupHistory = ref(false);
    const timeOut = computed(() => store.getTimeOut);
    let timeId;
    let tokenId = sessionStorage.getItem('id_token');
    const listPath = ['/login', '/forgot-password', '/reset-password', '/not-found', '/account-manager', '/notification-setting']
    router.beforeEach((to, from) => {
      isShowBtnGroup.value = to.path === CONST.PATH_SHOW_BTN_GROUP[0];
      isShowBtnGroupHistory.value = to.path === CONST.PATH_SHOW_BTN_GROUP[1];

      // remove logout item if logged in
      let logoutItems: any = localStorage.getItem('logoutItems');
      try {
        logoutItems = JSON.parse(logoutItems);
        // if logged in
        if (tokenId) {
          logoutItems = logoutItems.filter((item) => item.tokenId !== tokenId);
          localStorage.setItem('logoutItems', JSON.stringify(logoutItems));
        }
      } catch (error) {
      }
    });

    // save token to local storage before unload
    window.addEventListener('unload', function (e) {
      e.preventDefault();
      let logoutItems: any = localStorage.getItem('logoutItems');
      let tokenId = sessionStorage.getItem('id_token');
      let accountId = sessionStorage.getItem('account_id');
      let userId = sessionStorage.getItem('user_id');
      if (!tokenId) return;
      try {
        logoutItems = JSON.parse(logoutItems);
        if (!Array.isArray(logoutItems)) {
          logoutItems = [];
        }
      } catch (error) {
        logoutItems = [];
      }
      if (!logoutItems.find((item) => item.tokenId === tokenId)) {
        logoutItems.push({
          accountId: accountId,
          userId: userId,
          tokenId: tokenId
        });
      }
      localStorage.setItem('logoutItems', JSON.stringify(logoutItems));
    });

    provide('alertService', useAlertService());

    watch(() => store.errorRouter, (newVal) => {
      if (newVal) {
        store.setErrorRouter(false);
        if (fromNamePath.value !== CONST.LOGIN_NAME) {
          errorRouterMes.value = 'error router';
          setTimeout(() => {
            errorRouterMes.value = '';
          }, 2000);
        }
      }
    });
    router.beforeResolve(async (to, from) => {
      fromNamePath.value = from.name;
    });
    onMounted( async () => {
      try {
        router.beforeResolve(async (to, from) => {
          fromNamePath.value = from.name
          if (!listPath.includes(to.path) ) {
            if (localStorage.currentLanguage !== 'ja') {
              try {
                const res = await settingService.getLanguage(localStorage.currentLanguage, '');
                store.setTranslateList(res.data);
              } catch (error) {
              }
            } else {
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    })
    // check time logout
    watch(timeOut, (newVal) => {
      if (timeId) {
        clearTimeout(timeId);
      };
      let time
      switch (newVal) {
        case '0':
          break;
        case '1':
          time = '300000';
          break;
        case '2':
          time = '600000';
          break;
        case '3':
          time = '1800000';
          break;
        case '4':
          time = '3600000';
          break;
      }

      let timeOn = sessionStorage.getItem('time-on');
      if (time && timeOn && (new Date()).getTime() - Number(timeOn) > Number(time)) {
        afterLogin();
        return
      }

      sessionStorage.setItem('time-on', `${(new Date()).getTime()}`)

      if (newVal !== '' && time) {
        timeId = setTimeout(() => {
          try {
            afterLogin();
          } catch (error) {
          }
        }, time);
      }
    });

    const afterLogin = () => {
      logout();
      store.setTime("");
      store.setOpenSessionTimeOut(true);
      store.openSetting(false)
    }

    function trapFocus(element) {
      const focusableEls = element.querySelectorAll('a[href]:not([disabled]), button:not([disabled]):not(.disable, .icon-bus-map), textarea:not([disabled]), input:not([disabled]):not(.exportFile), select:not([disabled])');
      const firstInput = element.querySelector('textarea:not([disabled]), input:not([disabled]):not(.exportFile), select:not([disabled])');
      const firstFocusableEl = focusableEls[0];
      const lastFocusableEl = focusableEls[focusableEls.length - 1];
      const firstFocus = firstInput ? firstInput : element;
      let currentIndex = 0;
      const KEYCODE_TAB = 9;
      focusableEls.forEach((element, index) => {
        if (element === firstInput) {
          currentIndex = index;
        }
      });
      firstFocus.focus();
      const keyDownHandle = (e) => {
        const isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
        if (!isTabPressed) {
          return;
        }
        // if current index different activeElement then reassign currrent index
        if (document.activeElement !== focusableEls[currentIndex]) {
          let newIndex = Array.from(focusableEls).findIndex(el => el === document.activeElement);
          currentIndex = newIndex !== -1 ? newIndex : currentIndex;
        }
        if (e.shiftKey) {
          if (document.activeElement === firstFocusableEl) {
            currentIndex = focusableEls.length - 1;
          } else {
            currentIndex--;
          }
        } else {
          if (document.activeElement === lastFocusableEl || currentIndex === focusableEls.length) {
            currentIndex = 0;
          } else {
            currentIndex++;
          }
        }
        focusableEls[currentIndex]?.focus();
        e.preventDefault();
      }
      element.removeEventListener('keydown', keyDownHandle);
      element.addEventListener('keydown', keyDownHandle);
    }

    const startObserving = (domNode, classToLookFor) => {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(function (mutation) {
          Array.from(mutation.addedNodes).some((element: any) => {
            let focusArea = element?.classList?.contains(classToLookFor) ? element : element?.firstChild?.classList?.contains(classToLookFor) ? element.firstChild : null;
            if (focusArea) {
              trapFocus(focusArea);
              return true;
            }
            return false;
          });
        });
      });

      observer.observe(domNode, {
        childList: true,
        attributes: true,
        characterData: true,
        subtree: true,
      });

      return observer;
    };
    startObserving(document.body, 'modal');

    // clear token at 4am every day
    let tokenTimeout = null;
    function clearToken() {
      if (tokenTimeout) {
        clearTimeout(tokenTimeout);
      }
      let now = new Date();
      let expirationTime = new Date(now);
      if (expirationTime.getHours() >= 4) { // if after 4am then clear token at 4am this next
        expirationTime.setDate(expirationTime.getDate() + 1);
      }
      expirationTime.setHours(4, 0, 0, 0);
      let liveTime = expirationTime.getTime() - now.getTime();
      if (liveTime > 0) {
        tokenTimeout = setTimeout(() => {
          // clear token and redirect to login
          clearState();
          router.push({
            name: 'Login',
          });
        }, liveTime);
      }
    }
    router.beforeEach(() => {
      clearToken();
    });

    return {
      t$: useI18n().t,
      isLoading,
      errorRouterMes,
      isShowBtnGroup,
      isShowBtnGroupHistory
    };
  },
});
