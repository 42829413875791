import { defineStore } from 'pinia';
import UserService from '@/services/user/user.service';
import { HttpStatusCode } from 'axios';
import { useI18n } from 'vue-i18n';
import { CONST } from '@/constants'
import { useStore } from '@/store';

const ASC = true;
const DESC = false;
const DEFAULT_ERR_CODE = 'msg0000';
const ROLE_NAME_MAPPING = {
  "0": "general",
  "1": "manager",
  "2": 'admin',
  "3": 'root',
  "4": 'lecip'
}
const userService = new UserService();

export interface UserStateStorable {
  listUser: Array<any>;
  selectedUser: any;
  filtered: {
    office: string,
    role: string,
    display: string
  };
  sortStatus: {
    prop: string,
    direction: Boolean
  };
}

export const defaultUserState: UserStateStorable = {
  listUser: [
  ],
  filtered: {
    office: '',
    role: '',
    display: ''
  },
  sortStatus: {
    prop: 'no',
    direction: ASC
  },
  selectedUser: null,
}

export const useUserStore = defineStore('userStore', {
  state: (): UserStateStorable => ({ ...defaultUserState }),
  getters: {
    getAllUser: (state) => {
      return state.listUser;
    },
    getListUserFiltered: (state) => {
      let users = state.listUser.filter(function (user) {
        return (!state.filtered.office || user.officeName === state.filtered.office) && (!state.filtered.role || user.roleId === state.filtered.role);
      });
      return [...users].sort((u1, u2) => {
        let s1 = u1[state.sortStatus.prop] ? '' + u1[state.sortStatus.prop] : '';
        let s2 = u2[state.sortStatus.prop] ? '' + u2[state.sortStatus.prop] : '';
        return (state.sortStatus.direction ? 1 : -1) * s1.localeCompare(s2);
      });
    },
    getSelectedUser: (state) => {
      return state.listUser.find((user) => {
        return user.userId === state.selectedUser?.userId;
      });
    },
    getDisplayFiltered: (state) => {
      const { t } = useI18n();
      return (`${state.filtered.office}, ${state.filtered.role ? t(`userManager.fields.role.options.${ROLE_NAME_MAPPING[state.filtered.role]}`) : ''}`).replace(/(,\s*$)|(^,\s)/, "");
    }
  },
  actions: {
    resetSortStatus() {
      this.sortStatus = { ...defaultUserState.sortStatus };
    },
    fetchAllUser: async function () {
      try {
        const res = await userService.fetchAllUser();
        if (res && res.status === HttpStatusCode.Ok) {
          let i = 0;
          this.listUser = res.data.sort((u1, u2) => {
            const isNull = (value) => value === null || value === undefined;
            if (isNull(u1.created_time) && isNull(u2.created_time)) {
              return 0;
            } else if (isNull(u1.created_time)) {
              return -1;
            } else if (isNull(u2.created_time)) {
              return 1;
            } else {
              return u1.created_time.localeCompare(u2.created_time);
            }
          }).map(function (user) {
            i++;
            return {
              no: i.toString().padStart(3, '0'),
              accountId: user.account_id,
              email: user.email,
              isLocked: user.is_locked,
              officeName: user.office_name,
              officeId: user.office_id,
              roleId: user.role_id,
              roleName: ROLE_NAME_MAPPING[user.role_id],
              userId: user.user_id,
              userName: user.username
            }
          });
          useStore().setLoading(false);
        } else {
          this.listUser = [];
        }
      } catch (error) {
        this.listUser = [];
      }
    },
    selectUser: function (user) {
      this.selectedUser = this.selectedUser !== user ? user : null;
    },
    sort: function (prop) {
      this.sortStatus.direction = this.sortStatus.prop === prop ? !this.sortStatus.direction : ASC;
      this.sortStatus.prop = prop;
    },
    filter: function (office, role) {
      this.filtered.office = office;
      this.filtered.role = role;
    },
    deleteUser: async function () {
      try {
        let res = await userService.deleteUserById(this.selectedUser.userId);
        let error = !(res && res.status === HttpStatusCode.Ok);
        useStore().setLoading(false);
        return {
          error: error,
          errorCode: error? (res.data && res.data.message && CONST.REGEX_MSG_CODE.test(res.data.message) ? res.data.message : DEFAULT_ERR_CODE) : ''
        };
      } catch (error) {
        return {
          error: true,
          errorCode: error.data && error.data.message && CONST.REGEX_MSG_CODE.test(error.data.message) ? error.data.message : DEFAULT_ERR_CODE
        }
      }
    },
    unlockUser: async function() {
      try {
        let res = await userService.unlockUser(this.selectedUser.userId);
        let error = !(res && res.status === HttpStatusCode.Ok);
        useStore().setLoading(false);
        return {
          error: error,
          errorCode: error ? (res.data && res.data.message && CONST.REGEX_MSG_CODE.test(res.data.message) ? res.data.message : DEFAULT_ERR_CODE) : ''
        };
      } catch (error) {
        return {
          error: true,
          errorCode: error.data && error.data.message && CONST.REGEX_MSG_CODE.test(error.data.message) ? error.data.message : DEFAULT_ERR_CODE
        }

      }
    }
  }
});
