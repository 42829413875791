import { defineStore } from 'pinia';
import { HttpStatusCode } from 'axios';
import { CONST } from '@/constants';
import { useDateFormat, DATE_FORMAT, useDateUtils } from '@/shared/composables';
import StopService from '@/services/monitoring/detail/stop.service';
import { useStore, useVehicleStore } from '@/store';
import { computed } from 'vue';
import _ from 'lodash';

const stopService = new StopService();
export interface DetailStopStateStorable {
  busStop: Object;
  selectedDate: Date;
  routes: Object;
  schedules: Array<any>;
}

export const defaultDetailStopState: DetailStopStateStorable = {
  busStop: {},
  selectedDate: useDateUtils().handleConvertTime(new Date()),
  routes: {},
  schedules: []
}

export const useDetailStopStore = defineStore('detailStopStore', {
  state: (): DetailStopStateStorable => ({ ...defaultDetailStopState }),
  getters: {
    getSchedules: function (state) {
      return state.schedules.map(function (schedule) {
        const listRoute = useVehicleStore().listRoutes;
        const foundRecord = listRoute.find(record => record?.route_name === schedule?.route_name && record?.jp_pattern_name === schedule?.trip_name)
        return {
          departureTime: schedule.event_time,
          vehicleNumber: schedule.vehicle_id,
          delay: schedule.event_early_delay,
          routeShortName: schedule.route_name,
          routeName: schedule.trip_name,
          work: schedule.trip_id,
          trip_headsign: schedule.trip_headsign,
          jp_pattern_name: foundRecord.jp_pattern_name,
          route_id: foundRecord.route_id,
          route_name: foundRecord.route_name,
          jp_pattern_id: foundRecord.jp_pattern_id,
        }
      });
    },
    getRoutesViaStop: function (state) {
      let routes = [];
      _.forEach(state.routes, (value) => {
        const key = _.findKey(state.routes, value);
        value['jp_pattern_name'].forEach((patternName, index) => {
          routes.push({
                route_name: value['route_name'],
                route_id: key,
                route_color: value['route_color'],
                jp_pattern_name: patternName,
                jp_pattern_id: value['jp_pattern_id'][index],
                trip_headsign: value['trip_headsign'][index]
            });
        });
    });
    return routes;
    },
    getSelectedDate: function (state) {
      let formatted = useDateFormat().formatDate(state.selectedDate, DATE_FORMAT);
      let dayName = CONST.DAY_NAMES[state.selectedDate.getDay()];
      return {
        value: state.selectedDate,
        formatted: formatted,
        dayName: dayName
      };
    },
    allowNextDate: function () {
      let currentDate = new Date();
      let currentDateString = useDateFormat().formatDate(currentDate, DATE_FORMAT);
      return this.getSelectedDate.formatted < currentDateString;
    },

    allowPrevDate: function () {
      let currentDate: any = new Date();
      let fourteenDaysAgo = new Date(currentDate - 14 * 24 * 60 * 60 * 1000);
      let fourteenDaysAgoString = useDateFormat().formatDate(fourteenDaysAgo, DATE_FORMAT);
      return this.getSelectedDate.formatted > fourteenDaysAgoString;
    }
  },
  actions: {
    resetSelectedDate: function() {
      this.selectedDate =  useDateUtils().handleConvertTime(new Date());
    },

    fetchStopSchedule: async function (busStopId: string, date: string) {
      try {
        let res = await stopService.fetchStopSchedule(busStopId, date);
        if (res.status === HttpStatusCode.Ok) {
          this.schedules = res.data;
          useStore().setLoading(false);
        }
      } catch (error) {
        this.schedules = [];
      }
    },
    fetchRouteViaStop: async function (busStopId: string, date: string) {
      try {
        let res = await stopService.fetchRouteViaStop(busStopId, date);
        if (res.status === HttpStatusCode.Ok) {
          this.routes = res.data;
          useStore().setLoading(false);
        }
      } catch (error) {
        this.routes = {};
      }
    },
    setSelectedDate: function (newDate: Date) {
      this.selectedDate = newDate;
    }
  }
});
