import { defineStore } from 'pinia';
import DeviceService from '@/services/device/device.service';
import UserService from '@/services/user/user.service';
import { HttpStatusCode } from 'axios';
import { CONST } from '@/constants';
const deviceService = new DeviceService();
const userService = new UserService();
import { useStore } from '@/store';

const ASC = true;
const DESC = false;

export interface DeviceStateStorable {
  listDevice: Array<any>;
  selectedDevice: any;
  keySearch: string;
  enableSearch: Boolean;
  filtered: {
    office: string,
    display: string
  };
  sortStatus: {
    prop: string,
    direction: Boolean
  };
  listOffices: Array<any>,
  selectedFilter: string;
  count: number
}

export const defaultDeviceState: DeviceStateStorable = {
  listDevice: [],
  keySearch: '',
  enableSearch: false,
  filtered: {
    office: '',
    display: ''
  },
  sortStatus: {
    prop: 'device_id',
    direction: ASC
  },
  selectedDevice: null,
  listOffices: [],
  selectedFilter: '',
  count: 0,
}

export const useDeviceStore = defineStore('deviceStore', {
  state: (): DeviceStateStorable => ({ ...defaultDeviceState }),
  getters: {
    getDeviceMatched: function(state) {
      if (state.enableSearch && state.keySearch) {
        return this.getDeviceByOffice.filter(function (bus) {
          var isMatch = false;
          Object.keys(bus).forEach(function (key) {
            if (String(bus[key]).toLowerCase().includes(state.keySearch.toLowerCase().toString()) && key !== "account_id") {
              isMatch = true;
              return;
            }
          });
          return isMatch;
        });
      }

      return [];
    },
    getDeviceByOffice: (state) => {
      let prop = state.sortStatus.prop;
      let filtered = state.filtered.office ? state.listDevice.filter(device => device.office_name === state.filtered.office) : state.listDevice;
      filtered = [...filtered].sort((d1, d2) => (state.sortStatus.direction ? 1 : -1) * d1[prop].localeCompare(d2[prop]));
      return filtered;
    },
    getOffices: (state) => {
      return state.listOffices;
    },
    getSelectedFilter: (state) => {
      return state.selectedFilter;
    },
    getMapOffice: function (state) {
      const mapOffice = new Map();
      for (let i = 0; i < state.listOffices.length; i++) {
        mapOffice.set(state.listOffices[i].office_id, state.listOffices[i]);
      }
      return mapOffice;
    }
  },
  actions: {
    resetSortStatus() {
      this.sortStatus = { ...defaultDeviceState.sortStatus };
    },
    search (keySearch) {
      this.keySearch = keySearch;
      this.enableSearch = true;
    },
    filterByOffice: function (office) {
      this.filtered.office = office;
      localStorage.setItem('filterDevice',office)
      this.filtered.display = office;
    },
    addDevice: async function (device) {
      await deviceService.addDevice(device)
      useStore().setLoading(false);
    },
    deleteDevice:async function (device) {
      return await deviceService.deleteDevice(device)
    },
    selectDevice: function (device) {
      this.selectedDevice = device === this.selectedDevice ? null : device;
    },
    sort: function (prop) {
      this.sortStatus.direction = this.sortStatus.prop === prop ? !this.sortStatus.direction : ASC;
      this.sortStatus.prop = prop;
    },
    fetchAllDevice: function() {
      let offices = this.getMapOffice;
      deviceService.fetchAllDevice().then(res => {
        this.listDevice = res.data.map(device => {
          return {
            account_id: device.account_id || '',
            device_id: device.device_id || '',
            ip_address: device.ip_address || '',
            last_send: device.last_send || '',
            office_id: device.office_id || '',
            register_datetime: device.register_datetime || '',
            register_name: device.register_name ? (device.register_name === CONST.ROOT_NAME.ROOT ? CONST.ROOT_NAME.LECIP : device.register_name) : '',
            vehicle_id: device.vehicle_id || '',
            office_name: offices.get(device.office_id)?.office_name || '',
          };
        });
        useStore().setLoading(false);
      })
    },
    fetchAllOffices: async function() {
      try {
        const res = await userService.getOffices();
        if (res && res.status === HttpStatusCode.Ok) {
          this.listOffices = JSON.parse(res.data.list_offices);
          useStore().setLoading(false);
        } else {
          this.listOffices = [];
        }
      } catch (error) {
        this.listOffices = [];
      }
    },
    setFilter: function (param) {
      this.selectedFilter = param;
    },
    setKeySearch: function(keysearch) {
      this.keySearch = keysearch;
    }
  }
});
