import { defineStore } from 'pinia';

export interface RouteStateStorable {
  isShowWanningModal: Boolean;
  isEditing: Boolean;
  isCallLogout: Boolean;
  screenSelectName: string
}

export const defaultRouteState: RouteStateStorable = {
  isShowWanningModal: false,
  isEditing: false,
  isCallLogout: false,
  screenSelectName: ''
}

export const useRouteStore = defineStore('routeStore', {
  state: (): RouteStateStorable => ({ ...defaultRouteState }),
  getters: {
  },
  actions: {
    setIsShowWanningModal: function (value) {
      this.isShowWanningModal = value;
    },

    setScreenSelectName: function (value) {
      this.screenSelectName = value;
    },

    setIsCallLogout: function (value) {
      this.isCallLogout = value;
    },

    setIsEditing: function (value) {
      this.isEditing = value;
    },
  }
});
