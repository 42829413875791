import { customAxios } from "@/shared/config/axios-interceptor";

const PATH_HISTORY = '/monitors/history';

export default class UserService {
  public async getOffices(): Promise<any> {
    try {
      const res = await customAxios.get(`/users/offices`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async getListHistory(params): Promise<any> {
    try {
      const res = await customAxios.get(`${PATH_HISTORY}?event_time_from=${params.eventTimeFrom}&event_time_to=${params.eventTimeTo}&office_id=${params.officeId}&route_name=${params.routeName}&trip_name=${params.tripName}&bus_stop_name=${params.busStopName}&vehicle_id=${params.vehicleId}&event_early_delay=${params.eventEarlyDelay}&event_type=${params.event}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async getOfficeUser(param): Promise<any> {
    try {
      const path = `/users/${param}`;
      const res = await customAxios.get(path)
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async getFilterHistory(param): Promise<any> {
    try {
      const path = `/monitors/history/filter?office_id=${'all'}`;
      const res = await customAxios.get(path)
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async getFileExportMonth(params): Promise<any> {
    try {
      const path = `/monitors/history/export-month?office_id=${params.offfice}&month_export=${params.date}`;
      const res = await customAxios.get(path)
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async getFileExportDay(params): Promise<any> {
    try {
      const path = `/monitors/history/export-day?office_id=${params.offfice}&day_export=${params.date}`;
      const res = await customAxios.get(path)
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async getFilterHistoryHidden(): Promise<any> {
    try {
      const path = `/monitors/history/filter-event-type`;
      const res = await customAxios.get(path)
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }
  public async getOfficeOfUser(param): Promise<any> {
    try {
      const path = `/users/${param}`;
      const res = await customAxios.get(path)
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }
  public async getVehicleGpsFilter(params): Promise<any> {
    try {
      const path = `/monitors/history/gps/params?pdate=&vehicle_id=`;
      const res = await customAxios.get(path)
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }
  public async getStopGpsFilter(params): Promise<any> {
    try {
      const path = `/monitors/history/gps/params?pdate=${params.pdate}&vehicle_id=${params.vehicle_id}`;
      const res = await customAxios.get(path)
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }
  public async getDataGps(params): Promise<any> {
    try {
      const path = `/monitors/history/gps?vehicle_id=${params.vehicle}&time_start=${params.startDate}&time_end=${params.endDate}&stop_name=${params.stop}`;
      const res = await customAxios.get(path);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }
}

