import { customAxios } from "@/shared/config/axios-interceptor";
import _ from 'lodash';

export default class ScheduleService {
  public async getDetailSetting(): Promise<any> {
    try {
      const res = await customAxios.get('/setting');
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async saveSetting(params): Promise<any> {
    const data = {
      autoLogoutTime: params.timeLogout,
      language: params.lang,
    }
    try {
      const res = await customAxios.put('/setting',JSON.stringify(data));
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
  public async refreshToken(): Promise<any> {
    const refreshToken = sessionStorage.getItem('refresh_token')
    const data = {
      refresh_token : refreshToken
    }
    try {
      const res = await customAxios.post('/refresh-token',JSON.stringify(data));
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async getLanguage(lang, masterId, date?): Promise<any> {
    try {
      const res = await customAxios.get(`/setting/${lang}?master_id=${masterId}&date=${date || ''}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

}
