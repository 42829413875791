const ListVehicle = () => import('@/features/monitoring/list/list-vehicle.vue');
const DetailVehicle = () => import('@/features/monitoring/detail/detail-vehicle.vue');
const DetailStop = () => import('@/features/monitoring/detail/detail-stop.vue');

export default [
  {
    path: '/monitoring/list',
    name: 'ListVehicle',
    component: ListVehicle,
  },
  {
    path: '/monitoring/detail-vehicle',
    name: 'DetailVehicle',
    component: DetailVehicle,
  },
  {
    path: '/detail-stop',
    name: 'DetailStop',
    component: DetailStop,
  },
];
