import { customAxios } from '@/shared/config/axios-interceptor';
const PATH_LOGIN = '/login'
const PATH_FORGOT_PASSWORD = '/forgot-password'
const PATH_RESET_PASSWORD = '/forgot-password'
const PATH_LOGOUT = '/logout'

export default class LoginService {
  public async login(param: { accountId: string, userId: string, password: string, isConfirmContinueLogin: boolean }): Promise<any> {
    try {
      const data = {
        account_id: param.accountId,
        user_id: param.userId,
        password: param.password
      };
      param.isConfirmContinueLogin && (data['is_confirm_continue_login'] = param.isConfirmContinueLogin)
      const res = await customAxios.post(PATH_LOGIN, data)
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async forgotPassword(param: { accountId: string, userId: string }): Promise<any> {
    try {
      var path = `${PATH_FORGOT_PASSWORD}?accountID=${param.accountId}&userID=${param.userId}`;
      const res = await customAxios.get(path)
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async resetPassword(param: { confirmCode: string, password: string, userId: string, accountId: string }): Promise<any> {
    try {
      const data = {
        password: param.password,
        confirm_code: param.confirmCode,
        account_id: param.accountId,
        user_id: param.userId,
      };
      const res = await customAxios.post(PATH_RESET_PASSWORD, data);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }

  public async logout(param: { userId: string, accountId: string }): Promise<any> {
    try {
      var path = `${PATH_LOGOUT}?accountID=${param.accountId}&userID=${param.userId}`;
      const res = await customAxios.get(path)
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }
  public async getOfficeUser(param): Promise<any> {
    try {
      var path = `/users/${param}`;
      const res = await customAxios.get(path)
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }
}
