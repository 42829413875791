import { defineStore } from 'pinia';

export interface RoleUserStateStorable {
  hasChanged: Boolean,
  isShowWanningModal: Boolean,
  actionAcceptWarn: Function
}

export const defaultRoleUserState: RoleUserStateStorable = {
  hasChanged: false,
  isShowWanningModal: false,
  actionAcceptWarn: () => {}
}

export const useRoleUserStore = defineStore('roleUserStore', {
  state: (): RoleUserStateStorable => ({ ...defaultRoleUserState }),
  getters: {
    getHasChanged: function (state) {
      return state.hasChanged;
    },
    getShowWarningModal: function (state) {
      return state.isShowWanningModal;
    }
  },
  actions: {
    setHasChanged: function (hasChanged) {
      this.hasChanged = hasChanged;
    },
    setShowWanningModal: function (isShow) {
      this.isShowWanningModal = isShow;
    },
    setActionAcceptWarn: function (next) {
      if (typeof next === 'function') {
        this.actionAcceptWarn = next;
      } else {
        this.actionAcceptWarn = () => {};
      }
    }
  }
});
