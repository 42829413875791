import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { useScheduleStore } from '@/store';

export default defineComponent({
  compatConfig: { MODE: 3 },
  setup() {
    const store = useScheduleStore();
    return {
      t$: useI18n().t,
      store: store
    };
  },
});