import { customAxios } from "@/shared/config/axios-interceptor";

const PATH_SCHEDULE_STOP = '/monitors/stops';
const PATH_ROUTE_STOP = '/monitors/routes';

export default class StopService {

  /**
   * Get detail stop by bus stop name and date
   * @param busStopName Bus stop name
   * @param date Date history
   * @returns Detail stop
   */
  public async fetchStopSchedule(busStopId: string, date: string): Promise<any> {
    try {
      const res = await customAxios.get(`${PATH_SCHEDULE_STOP}?bus_stop_id=${busStopId}&date=${date}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async fetchRouteViaStop(busStopId: string, date: string): Promise<any> {
    try {
      const res = await customAxios.get(`${PATH_ROUTE_STOP}?bus_stop_id=${busStopId}&date=${date}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }
}

