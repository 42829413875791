import { customAxios } from "@/shared/config/axios-interceptor";

const PATH_BUS = '/vehicles';

export default class busService {
  public async fetchAllBus(): Promise<any> {
    try {
      const res = await customAxios.get(PATH_BUS);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async addBus(params: {officeId: string, vehicleId: string, deviceId: string, vehicleNumber: string}): Promise<any> {
    try {
      // list params
      const data = {
        office_id: params.officeId,
        vehicle_id: params.vehicleId,
        device_id: params.deviceId || null,
        vehicle_number: params.vehicleNumber || null,
      };
      const res = await customAxios.post(PATH_BUS, JSON.stringify(data));
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async editBus(oldValue, newValue,isConfirmContinueEdit): Promise<any> {
    try {
      // list params
      const data = {
        is_confirm_continue_edit: isConfirmContinueEdit,
        old_values: {
          office_id: oldValue.officeId,
          vehicle_id: oldValue.vehicleId,
          device_id: oldValue.deviceId || null,
          vehicle_number: oldValue.vehicleNumber || null,
        },
        new_values: {
          office_id: newValue.officeId,
          vehicle_id: newValue.vehicleId,
          device_id: newValue.deviceId || null,
          vehicle_number: newValue.vehicleNumber || null,
        }
      };
      const res = await customAxios.put(PATH_BUS, JSON.stringify(data));
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async fetchDeviceUnuse(): Promise<any> {
    try {
      const res = await customAxios.get(`${PATH_BUS}/devices/unused`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async deleteVehicle(vehicleId: string|number) : Promise<any> {
    try {
      const data = {
        vehicle_id: vehicleId
      }
      const res = await customAxios.delete(PATH_BUS,{data: JSON.stringify(data)});
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  }
}

