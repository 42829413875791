import { KEYS } from '@/constants';
import { computed, ref } from 'vue';
import axios from 'axios';
import _ from 'lodash';
import { useStore } from '@/store';
import { CONST } from '@/constants';
import router from '@/router';
const timeOut = ref(localStorage.getItem('timeOut'));

// config for customAxios
const customAxios = axios.create({
  baseURL: SERVER_API_URL,
  headers: {
    'Content-Type': 'application/x-amz-json-1.1',
    Accept: 'application/json',
  },
  timeout: 1000000,
});

const customAxiosBackground = axios.create({
  baseURL: SERVER_API_URL,
  headers: {
    'Content-Type': 'application/x-amz-json-1.1',
    Accept: 'application/json',
  },
  timeout: 1000000,
});

const refreshTokenAndRetry = async (config) => {
  const store = useStore()
  const res = await store.refreshToken();
  const newToken = res.data['AuthenticationResult'].IdToken;
  sessionStorage.setItem(KEYS.KEY_API_ID_TOKEN, newToken);
  axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
  return customAxios(config);
}

customAxios.interceptors.request.use(
  config => {
    useStore().setLoading(true);
    const token = sessionStorage.getItem(KEYS.KEY_API_ID_TOKEN)
    if (!_.isEmpty(token)) {
      config.headers.Authorization = `${token}`;
    }
    return config
  },
  error => {
    useStore().setLoading(false);
    Promise.reject(error)
  },
);

customAxiosBackground.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem(KEYS.KEY_API_ID_TOKEN)
    if (!_.isEmpty(token)) {
      config.headers.Authorization = `${token}`;
    }
    return config
  },
  error => {
    Promise.reject(error)
  },
);

customAxios.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    if(err.config.url === '/refresh-token') {
        useStore().openSetting(false)
        sessionStorage.clear();
        sessionStorage.setItem(CONST.TIME_OUT, 'true');
        useStore().setUserId('');
        router.push({ name: 'Login' });
    }
    if(err.response && err.response.status === 401){
      return refreshTokenAndRetry(err.config)
    }
    useStore().setLoading(false);
    return Promise.reject(err);
  }
);

customAxiosBackground.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    if(err.config.url === '/refresh-token') {
      useStore().openSetting(false)
      sessionStorage.clear();
      sessionStorage.setItem(CONST.TIME_OUT, 'true');
      useStore().setUserId('');
      router.push({ name: 'Login' });
  }
  if(err.response && err.response.status === 401){
    return refreshTokenAndRetry(err.config)
  }
    return Promise.reject(err);
  }
);

const setupAxiosInterceptors = (onUnauthenticated, onServerError) => {
  const onResponseError = err => {
    const status = err.status || err.response.status;
    if (status === 403 || status === 401 || status === 400) {
      return onUnauthenticated(err);
    }
    if (status >= 500) {
      return onServerError(err);
    }
    return Promise.reject(err);
  };
  if (customAxios.interceptors) {
    customAxios.interceptors.response.use(res => res, onResponseError);
  }
  if (customAxiosBackground.interceptors) {
    customAxiosBackground.interceptors.response.use(res => res, onResponseError);
  }
};

export {customAxios, setupAxiosInterceptors, customAxiosBackground };
