import { defineStore } from 'pinia';
import SettingService from '@/services/setting/setting.service';
import { useStore } from '@/store';

const settingService = new SettingService();
export interface AccountStateStorable {
  authenticationInfo: null | any;
  listDataDummy: Object;
  listDataPermission : Object,
  userId: String
  listAccount: Object,
  isLoading: Boolean,
  errorRouter: Boolean,
  timeOut: string,
  isOpenSetting: Boolean
  detailSetting: Object,
  isOpenSessionTimeOut: Boolean,
  lang: string,
  permissionList: Array<any>,
  translateList: Array<any>,
  recallVehicleTime: any;
  zoomMonitor: any;
}

export const defaultAccountState: AccountStateStorable = {
  errorRouter: false,
  isLoading: false,
  listAccount: [],
  authenticationInfo: null,
  userId: '',
  listDataDummy: [],
  permissionList: [],
  translateList: [],
  listDataPermission: [
  "decentralization.accountManagement",
  "decentralization.userManagement",
  "decentralization.vehicleDynamicsMonitoring",
  "decentralization.masterDataManagement",
  "decentralization.editRouteSpot",
  "decentralization.editVehicleEquipment",
  "decentralization.settingDispatchSchedules",
  "decentralization.operationalResults",
],
timeOut: '',
isOpenSetting: false,
detailSetting: {},
isOpenSessionTimeOut: false,
lang: '',
recallVehicleTime: null,
zoomMonitor: null
}

export const useAccountStore = defineStore('accountStore', {
  state: (): AccountStateStorable => ({ ...defaultAccountState }),
  actions: {
    setUserId(userId) {
      this.userId = userId
    },
    setErrorRouter(errorRouter) {
      this.errorRouter = errorRouter
    },
    setLoading(isLoading) {
      this.isLoading = isLoading
    },
    setAuthenticationInfo(info) {
      this.authenticationInfo = info
    },
    setTime: function(param){
      this.detailSetting["autoLogoutTime"] = param;
      localStorage.setItem("timeOut",param)
    },
    fetchDetailSetting: async function () {
      const res = await settingService.getDetailSetting();
      if(res.status === 200) {
        this.detailSetting = res.data
        useStore().setLoading(false);
      }
      return res;
    },
    fetchTimeLogout: async function(){
      try {
        const res = await settingService.getDetailSetting();
        if(res.status === 200) {
          this.recallVehicleTime = res.data["auto_update_vehicle_monitor_screen"];
          this.zoomMonitor = res.data["vehicle_monitor_screen_zoom"];
          this.detailSetting["autoLogoutTime"] = res.data.autoLogoutTime;
          localStorage.setItem("timeOut",res.data.autoLogoutTime);
          useStore().setLoading(false);
        }
        return res;
      } catch (error) {
        // to do
      }
    },
    openSetting: function(param){
      this.isOpenSetting = param;
    },
    refreshToken: async function(){
      return await settingService.refreshToken()
    },
    setOpenSessionTimeOut: function(param){
      this.isOpenSessionTimeOut = param
    },
    setLang: function(param){
      this.lang = param;
    },

    setPermissionList: function(param){
      this.permissionList = param;
    },

    setTranslateList: function(param){
      this.translateList = param;
      this.setLoading(false);
    }
  },
  getters: {
    getTimeOut: function(state){
      return state.detailSetting['autoLogoutTime'];
    },
    getStatusSetting: function(state){
      return state.isOpenSetting;
    },
    getStatusOpenSessionTimeOut: function(state){
      return state.isOpenSessionTimeOut;
    },
    getLang: function(state){
      return state.lang;
    }
  }
});
