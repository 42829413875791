import { defineStore } from 'pinia';
import ScheduleService from '@/services/schedule/schedule.service';
import { useStore } from '@/store';
import { HttpStatusCode } from 'axios';
import { useDateFormat, DATE_FORMAT } from '@/shared/composables';
import { CONST } from '@/constants';
import { useI18n } from 'vue-i18n';
import { useDateUtils } from '@/shared/composables';
import _ from 'lodash';
import LoginService from '@/services/account/login.service';
import MasterService from '@/services/master/master.service';
import SettingService from '@/services/setting/setting.service';
import dayjs from 'dayjs';

const ASC = true;
const DESC = false;

export interface ScheduleStateStorable {
  workFrameInfo: any;
  listSchedule: Array<any>;
  listScheduleTemp: Array<any>;
  offices: Array<any>;
  listMatched: Array<any>;
  indexMatched: number;
  keySearch: string;
  sortStatus: {
    prop: string,
    direction: Boolean
  };
  sortStatusTrips: {
    prop: string,
    direction: Boolean
  };
  sortStatusOperations: {
    prop: string,
    direction: Boolean
  };
  showModal: {
    export: Boolean,
    replaceWork: Boolean,
    settingWork: Boolean
  };
  selectedDate: any,
  replaceArray: Array<any>;
  selectedOffice: string;
  listTrip: Array<any>;
  listOperation: Array<any>;
  listOperationOld: Array<any>;
  listOperationTemp: Array<any>;
  filters: Array<any>;
  officeUser: string;
  officeUserId: string;
  officeUserlogin: string;
  dataExport: Array<any>;
  listService: Array<any>;
  listRoute: Array<any>;
  allSchedules: any;
  listMaster: Object;
}

const scheduleService = new ScheduleService();
const loginService = new LoginService();
const masterService = new MasterService();
const settingService = new SettingService();

export const defaultScheduleState: ScheduleStateStorable = {
  workFrameInfo: null,
  listSchedule: [],
  listScheduleTemp: [],
  offices: [],
  listMatched: [],
  indexMatched: -1,
  keySearch: '',
  sortStatus: {
    prop: 'vehicleNumber',
    direction: ASC,
  },
  sortStatusTrips: {
    prop: 'startTime',
    direction: ASC,
  },
  sortStatusOperations: {
    prop: 'startTime',
    direction: ASC,
  },
  showModal: {
    export: false,
    replaceWork: false,
    settingWork: false,
  },
  selectedDate: useDateUtils().handleConvertTime(new Date()),
  replaceArray: [],
  selectedOffice: sessionStorage.getItem("officeUser") || '',
  listTrip: [],
  listOperation: [],
  listOperationOld: [],
  listOperationTemp: [],
  filters: [],
  officeUser: 'all',
  officeUserId: 'all',
  officeUserlogin: 'all',
  dataExport: [],
  allSchedules: {},
  listService: [],
  listRoute: [],
  listMaster: []
};

export const useScheduleStore = defineStore('useScheduleStore', {
  state: (): ScheduleStateStorable => ({ ...defaultScheduleState }),
  getters: {
    getShowModal: (state) => {
      return state.showModal;
    },
    getWorkFrameInfo: (state) => {
      return state.workFrameInfo;
    },
    getOffices: (state) => {
      return state.offices;
    },
    getScheduleDetail: function (state) {
      return state.listSchedule.map(function (schedule) {
        const resultLine = {};
        const resultTrips = []
        const colors = ['cl1', 'cl2', 'cl3']
        schedule?.trips?.forEach((trip, index) => {
          const key = `line${index + 1}`;
          const colorIndex = index % colors.length;
          const color = colors[colorIndex];
          resultLine[key] = {
            from: useDateUtils().convertTimeToDecimal(schedule?.trips[index].trip_start_time),
            to: useDateUtils().convertTimeToDecimal(schedule?.trips[index].trip_end_time),
            left: (state.workFrameInfo ? state.workFrameInfo.startPosition + (useDateUtils().convertTimeToDecimal(schedule?.trips[index].trip_start_time,) - state.workFrameInfo.startTime) * state.workFrameInfo.hourWidth + state.workFrameInfo.pseudoWidth : 0) + 'px',
            width: (state.workFrameInfo ? state.workFrameInfo.hourWidth * (useDateUtils().convertTimeToDecimal(schedule?.trips[index].trip_end_time) - useDateUtils().convertTimeToDecimal(schedule?.trips[index].trip_start_time,)) - state.workFrameInfo.pseudoWidth * 2 : 0) + 'px',
            color: color
          }
          resultTrips.push(resultLine[key])
        })
        return {
          jobId: schedule.jobId,
          jobDate: schedule.jobDate,
          selectedWork: schedule.selectedWork,
          noRegis: schedule.noRegis,
          drivingSetting: schedule.drivingSetting,
          vehicleNumber: schedule.vehicleNumber,
          office: schedule.office,
          officeName: schedule.officeName,
          trips: resultTrips,
          driverId: schedule.driverId,
          accountId: schedule.accountId,
          checked: schedule.checked
        }
      });
    },
    getSortedList: function (state) {
      let prop = state.sortStatus.prop;
      let filtered = [...this.getScheduleDetail].sort((s1, s2) => (state.sortStatus.direction ? 1 : -1) * s1[prop].localeCompare(s2[prop]));
      return filtered;
    },
    getMatchedList: function (state) {
      const result = this.getSortedList.filter(function (schedule) {
        return schedule?.vehicleNumber.toLowerCase().includes(state?.keySearch?.toLowerCase()) || schedule?.officeName?.toLowerCase().includes(state?.keySearch?.toLowerCase());
      });
      return result;
    },
    currentMatched: function (state) {
      const rs = this.getMatchedList.length && state.indexMatched !== -1 ? this.getMatchedList[state.indexMatched] : null;
      if (_.isEmpty(state.keySearch)) {
        return;
      }
      return rs;
    },
    getSelectedDate: function (state) {
      const t$ = useI18n().t;
      let formatted = useDateFormat().formatDate(state.selectedDate, DATE_FORMAT);
      let dayName = CONST.DAY_NAMES[state.selectedDate.getDay()];
      let formattedWithWeekday = `${formatted} (${t$(`date.weekday.${dayName}`)})`;
      return {
        value: state.selectedDate,
        formatted: formatted,
        formattedWithWeekday: formattedWithWeekday
      };
    },
    getMapOffice: function (state) {
      const mapOffice = new Map();
      for (let i = 0; i < state.offices.length; i++) {
        mapOffice.set(state.offices[i].office_id, state.offices[i]);
      }
      return mapOffice;
    },
    getTripsSort: function (state) {

      let prop = state.sortStatusTrips.prop;
      let filtered = state.listTrip.sort((s1, s2) => (state.sortStatusTrips.direction ? 1 : -1) * s1[prop].localeCompare(s2[prop]));
      return filtered;
    },
    getOperationsSort: function (state) {
      let prop = state.sortStatusOperations.prop;
      let filtered = state.listOperation.sort((s1, s2) => (state.sortStatusOperations.direction ? 1 : -1) * s1[prop].localeCompare(s2[prop]))
      return filtered;
    },
    getselectedDateWork: function (state) {
      return state.selectedDate
    },
    getOfficeUser: function (state) {
      return state.officeUser;
    },
    getOfficeUserId: function (state) {
      return state.officeUserId;
    },
    getAllSchedules: function (state) {
      return state.allSchedules;
    },
    getListScheduleTemp: function (state) {
      return state.listScheduleTemp;
    },
    getOfficeUserLogin: function (state) {
      return state.officeUserlogin;
    },
    getListMasterData: function(state) {
      return state.listMaster;
    }
  },
  actions: {
    resetSortStatus() {
      this.sortStatus = { ...defaultScheduleState.sortStatus };
    },
    resetSortStatusTrips() {
      this.sortStatusTrips = { ...defaultScheduleState.sortStatusTrips };
    },
    resetSortStatusOperations() {
      this.sortStatusOperations = { ...defaultScheduleState.sortStatusOperations };
    },
    openModal: function (modalId) {
      this.showModal[modalId] = true;
    },
    closeModal: function (modalId) {
      this.showModal[modalId] = false;
    },
    setWorkFrameInfo: function (workFrameInfo) {
      this.workFrameInfo = workFrameInfo;
    },
    search: function (keySearch) {
      if (!keySearch.localeCompare(this.keySearch)) {
        this.indexMatched = this.getMatchedList.length === this.indexMatched + 1 ? 0 : this.indexMatched + 1;
      } else {
        this.keySearch = keySearch;
        this.indexMatched = 0;
      }
    },
    sort: function (prop, arg) {
      if (arg === "trip") {
        this.sortStatusTrips.direction = this.sortStatusTrips.prop === prop ? !this.sortStatusTrips.direction : ASC;
        this.sortStatusTrips.prop = prop;
      } else if (arg === "operation") {
        this.sortStatusOperations.direction = this.sortStatusOperations.prop === prop ? !this.sortStatusOperations.direction : ASC;
        this.sortStatusOperations.prop = prop;
      } else {
        this.sortStatus.direction = this.sortStatus.prop === prop ? !this.sortStatus.direction : ASC;
        this.sortStatus.prop = prop;
      }
    },
    iniSearch: function () {
      this.keySearch = '';
      this.indexMatched = -1;
    },
    fetchOffices: async function () {
      try {
        const res = await scheduleService.getOffices(dayjs(this.selectedDate).format("YYYY-MM-DD"));
        if (res && res.status === HttpStatusCode.Ok) {
          this.offices = JSON.parse(res.data.list_offices);
        }
      } catch (error) {
      }
    },
    setSelectedDate: function (newDate) {
      this.selectedDate = newDate;
    },
    fetchJobs: async function () {
      try {
        let offices = this.getMapOffice;
        const params = {
          date: useDateFormat().formatDate(this.selectedDate, 'YYYY-MM-DD'),
          officeId: this.officeUserId
        };
        const res = await scheduleService.getJobs(params);
        if (res.status == 200) {
          this.listSchedule = res.data.map(schedule => {
            return {
              jobId: schedule.job_id,
              trips: schedule.trips,
              jobDate: schedule.job_date,
              selectedWork: false,
              noRegis: false,
              drivingSetting: {},
              vehicleNumber: schedule.vehicle_id,
              office: schedule.office_id,
              officeName: offices.get(schedule.office_id)?.office_name,
              accountId: schedule.account_id,
              driverId: schedule.driver_id,
              vehicalId: schedule.vehicle_id,
              checked: false
            }
          });
          this.listScheduleTemp = _.cloneDeep(this.listSchedule);
          return res;
        }
      } catch (error) {
      }
    },
    filterOffice: function (officeName, officeId) {
      this.officeUser = officeName;
      this.officeUserId = officeId;
    },
    setOperation: function (params) {
      const isOverlapping = this.listOperation.some(time => {
        return (
          (params.startTime >= time.startTime && params.startTime <= time.endTime) ||
          (params.endTime >= time.startTime && params.endTime <= time.endTime)
        );
      });
      if ((this.listOperation.findIndex(item => item.tripId === params.tripId) === -1) && !isOverlapping) {
        this.listOperation.push(params);
        const index = this.listTrip.indexOf(params);
        this.listTrip.splice(index, 1);
      }

      if (this.listOperationTemp.find(item => item.tripId === params.tripId)) {
        const index = this.listOperationTemp.indexOf(params);
        this.listOperationTemp.splice(index, 1);
      }
    },
    revertOperation: function (params) {

      if (this.listOperationOld.find(item => item.tripId === params.tripId)) {
        this.listOperationTemp.push(params);
      }
      const index = this.listOperation.indexOf(params);
      this.listOperation.splice(index, 1);
      this.listTrip.push(params);
    },
    setJob: async function (job) {
      const params = {
        job: job,
        trips: this.listOperation
      }
      const res = await scheduleService.setJobs(params);
      return res;
    },
    getJob: async function (params, filterWorkModel) {
      const res = await scheduleService.getJob(params);
      if (res.status === 200) {
        this.listTrip = res.data.list_trips.map(trip => {
          return {
            tripId: trip.trip_id,
            pattern: trip.jp_pattern_name || '',
            startTime: trip.trip_start_time,
            endTime: trip.trip_end_time,
            serviceId: trip.service_id,
            routeId: trip.route_id,
            routeName: trip.route_name,
            jpPatternId: trip.jp_pattern_id,
            tripHeadSign: trip.trip_headsign,
            stopHeadSign: trip.stop_headsign
          }
        })

        if (this.listOperation.length > 0) {
          this.listOperation.forEach(trip => {
            const index = this.listTrip.findIndex(item => item.tripId === trip.tripId);
            if (index >= 0) {
              this.listTrip.splice(index, 1);
            }
          })
        }
        if (this.listOperationTemp.length > 0) {
          this.listOperationTemp.forEach(item => {
            if ((item.serviceId === filterWorkModel.serviceId || !filterWorkModel.serviceId)
              && (item.routeId === filterWorkModel.routeId || !filterWorkModel.routeId)
              && (item.jpPatternId === filterWorkModel.patternId || !filterWorkModel.patternId)) {
              this.listTrip.push(item);
            }
          });
        }
      }
    },
    getFilters: async function (params) {
      this.filters = [];
      this.listService = [];
      this.listRoute = [];
      try {
        const res = await scheduleService.getFilters(params);
        if (res.status === 200) {
        this.filters = res.data["list_filter"];
        this.filters.sort((i1, i2) => i1["route_name"].localeCompare(i2["route_name"]));
        this.filters.sort((i1, i2) => i1["jp_pattern_name"].localeCompare(i2["jp_pattern_name"]));
        this.listService = res.data["list_service"].sort((i1, i2) => i1.localeCompare(i2));
        this.listRoute = _.uniqBy(this.filters, 'route_id');
        return res;
      }
      } catch (error) {
      }
    },
    refrestTrip: function () {
      this.listTrip = [];
      this.listOperation = [];
    },
    getUserOffice: async function () {
      const res = await loginService.getOfficeUser('id');
      if (res.status === 200) {
        if (_.isEmpty(res.data)) {
          this.officeUser = '';
          this.officeUserId = '';
          this.officeUserlogin = '';
        } else {
          this.officeUser = res.data[0].office_name;
          this.officeUserId = res.data[0].office_id;
          this.officeUserlogin = res.data[0].office_id;
        }
      }
    },
    getDataExport: async function (exportCondition) {
      try {
      const params = {
        officeId: _.isString(exportCondition.filterCondition) ? exportCondition.filterCondition : exportCondition.filterCondition.office_id,
        startDate: useDateFormat().formatDate(exportCondition.startDate, "YYYY-MM-DD"),
        endDate: useDateFormat().formatDate(exportCondition.endDate, "YYYY-MM-DD")
      }
      let offices = this.getMapOffice;
      const res = await scheduleService.getDataExport(params);
      if (res.status === 200) {
        this.dataExport = res.data.datas.map(record => {
          return {
            officeName: offices.get(record.office_id)?.office_name,
            officeId: record.office_id,
            jobId: record.job_id,
            jobDate: record.job_date,
            vehicleId: record.vehicle_id,
            driverId: record.driver_id,
            trips: record.trips
          }
        })
        }
      } catch (error) {
      }
    },
    replaceJobs: async function (jobOld, jobNew) {
      return await scheduleService.replaceJobs(jobOld, jobNew);
    },
    getDateHaveTrip: async function (officeId, jobDate) {
      const res = await scheduleService.getDateHaveTrip(officeId, jobDate);
      this.allSchedules = res.data.list_jobs_date_have_trips;

    },
    copySchedules: async function (officeId, jobDateFrom, jobDateToArray, listDatePreCheck) {
      return await scheduleService.copySchedules(officeId, jobDateFrom, jobDateToArray, listDatePreCheck);
    },
    setOperationTemp: function (ops) {
      this.listOperation = ops.map(trip => {
        return {
          tripId: trip.trip_id,
          pattern: trip.jp_pattern_name || '',
          startTime: trip.trip_start_time,
          endTime: trip.trip_end_time,
          serviceId: trip.service_id,
          routeId: trip.route_id,
          routeName: trip.route_name,
          jpPatternId: trip.jp_pattern_id,
          tripHeadSign: trip.trip_headsign,
          stopHeadSign: trip.stop_headsign,
        };
      });
      this.listOperationOld = _.cloneDeep(this.listOperation);
      this.listOperationTemp = [];
    },
    getlistMaster: async function () {
      try {
        const res = await masterService.getMasterList();
        this.listMaster = res.data;
      } catch (error) {
      }
    },
    getTranslateList: async function (params) {
      const res = await settingService.getLanguage(localStorage.currentLanguage, '', useDateFormat().formatDate(params,'YYYY-MM-DD'));
      useStore().setTranslateList(res.data);
      return res;
    }
  },
});
