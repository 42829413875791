const UserManager = () => import('@/features/maintain/user/user-manager.vue');
const AccountManager = () => import('@/features/maintain/account/account-manager.vue');
const MasterManager = () => import('@/features/maintain/master/master-manager.vue');
const BusDevice = () => import('@/features/maintain/bus-device/bus-device.vue');
const ScheduleDispatcher = () => import('@/features/maintain/schedule/schedule-dispatcher.vue');
const HistoryConfirm = () => import('@/features/maintain/history-confirm/history-confirm.vue');
const NotificationSetting = () => import('@/features/maintain/notification/notification-setting.vue');
const Map = () => import('@/features/maintain/history-confirm/map/map.vue')
const EditRoute = () => import('@/features/maintain/edit-route/edit-route.vue')
const UserNotification = () => import('@/features/maintain/user-notification/user-notification.vue');

export default [
  {
    path: '/user-manager',
    name: 'UserManager',
    component: UserManager,
  },
  {
    path: '/account-manager',
    name: 'AccountManager',
    component: AccountManager,
  },
  {
    path: '/master-manager',
    name: 'MasterManager',
    component: MasterManager,
  },
  {
    path: '/list-bus-device',
    name: 'BusDevice',
    component: BusDevice,
  },
  {
    path: '/schedule-dispatcher',
    name: 'ScheduleDispatcher',
    component: ScheduleDispatcher,
  },
  {
    path: '/history-confirm',
    name: 'HistoryConfirm',
    component: HistoryConfirm,
  },
  {
    path: '/notification-setting',
    name: 'NotificationSetting',
    component: NotificationSetting,
  },
  {
    path: '/map/:gps',
    name: 'Map',
    component: Map,
    meta: {hideHeader: true}
  },
  {
    path: '/map/:history',
    name: 'MapHistory',
    component: Map,
    meta: {hideHeader: true}
  },
  {
    path: '/edit-route',
    name: 'EditRoute',
    component: EditRoute
  },
  {
    path: '/user-notification',
    name: 'UserNotification',
    component: UserNotification
  }
];
