import { customAxios } from "@/shared/config/axios-interceptor";

const PATH_USER_NOTIFICATION = '/user-notification';

export default class NotificationService {
  public async fetchAllNotification(): Promise<any> {
    try {
      const res = await customAxios.get(PATH_USER_NOTIFICATION);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async addNotification(params: {content_notification: string, start_date: string, end_date: string, link: string, title: string,}): Promise<any> {
    try {
      const res = await customAxios.post(PATH_USER_NOTIFICATION, params);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async updateNotification(params: {content_notification: string, start_date: string, end_date: string, link: string, title: string,notification_id: string}): Promise<any> {
    try {
      const res = await customAxios.put(PATH_USER_NOTIFICATION, params);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async deleteNotification(notificationId: string): Promise<any> {
    const data = {
      notification_id: notificationId
    }
    try {
      const res = await customAxios.delete(`${PATH_USER_NOTIFICATION}`,{data: JSON.stringify(data)});
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

