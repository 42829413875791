import { defineStore } from 'pinia';
import NotificationService from '@/services/notification/notification.service';
import AccountService from '@/services/account/account.service';
import { HttpStatusCode } from 'axios';
import { CONST } from '@/constants';
import { useStore } from '@/store';
const ASC = true;
const DESC = false;
const DEFAULT_ERR_CODE = 'msg0000';

export interface NotificationStateStorable {
  listNotification: Array<any>;
  selectedNotification: any;
  sortStatus: {
    prop: string,
    direction: Boolean
  };
  listAccount: Array<any>
}

const notificationService = new NotificationService();
const accountService = new AccountService();

export const defaultNotificationState: NotificationStateStorable = {
  listNotification: [],
  sortStatus: {
    prop: 'releaseDateTime',
    direction: DESC
  },
  selectedNotification: null,
  listAccount: []
}

export const useNotificationStore = defineStore('notificationStore', {
  state: (): NotificationStateStorable => ({ ...defaultNotificationState }),
  getters: {
    getAllNotification: (state) => {
      return state.listNotification.map((notification) => {
        return {
          raw: notification,
          publisher: notification.publisher,
          startDate: notification.start_date,
          endDate: notification.end_date,
          contentNotification: notification.content_notification,
          releaseDateTime: notification.release_date_time,
          accReceiveNoti: notification.accounts_receive_notifications.filter(acc => state.listAccount.find((e) => acc.account_id === e.accountId)).map(acc => acc.agency_name).join(','),
          receivers: notification.accounts_receive_notifications.map((noti) => {
            return {
              accountId: noti.account_id,
              agencyName: noti.agency_name
            }
          }),
          selected: notification.release_date_time === state.selectedNotification?.raw.release_date_time
        }
      }).sort((u1, u2) => (state.sortStatus.direction ? 1 : -1) * u1[state.sortStatus.prop].localeCompare(u2[state.sortStatus.prop]));
    }
  },
  actions: {
    resetSortStatus() {
      this.sortStatus = { ...defaultNotificationState.sortStatus };
    },
    ini: async function () {
      this.selectedNotification = null;
      this.sortStatus = {
        prop: 'releaseDateTime',
        direction: DESC
      };
      this.listAccount = await this.getAccounts();
      useStore().setLoading(false);
    },
    selectNotification: function (notification) {
      this.selectedNotification = this.selectedNotification?.raw.release_date_time !== notification?.raw.release_date_time ? notification : null;
    },
    sort: function (prop) {
      this.sortStatus.direction = this.sortStatus.prop === prop ? !this.sortStatus.direction : ASC;
      this.sortStatus.prop = prop;
    },
    fetchAllNotification: async function () {
      try {
        const res = await notificationService.fetchAllNotification();
        if (res.status === HttpStatusCode.Ok) {
          this.listNotification = res.data;
        } else {
          this.listNotification = [];
        }
        useStore().setLoading(false);
      } catch (error) {
        this.listNotification = [];
      }
    },
    addNotification: async function (noti: {receivers: Array<any>, content: string, startDate: string, endDate: string}) {
      const params = {
        accounts_receive_notifications: noti.receivers.map((receiver) => {
          return {
            account_id: receiver.accountId,
            agency_name: receiver.agencyName
          }
        }),
        content_notification: noti.content,
        start_date: noti.startDate,
        end_date: noti.endDate 
      };
      try {
        let res = await notificationService.addNotification(params);
        let error = !(res && res.status === HttpStatusCode.Ok);
        useStore().setLoading(false);
        return {
          error: error,
          errorCode: error? (res.data && res.data.message && CONST.REGEX_MSG_CODE.test(res.data.message) ? res.data.message.toLowerCase() : DEFAULT_ERR_CODE) : ''
        };
      } catch (error) {
        return {
          error: true,
          errorCode: error.data && error.data.message && CONST.REGEX_MSG_CODE.test(error.data.message) ? error.data.message.toLowerCase() : DEFAULT_ERR_CODE
        }
      }
    },
    updateNotification: async function (noti: {releaseDateTime: string, receivers: Array<any>, content: string, startDate: string, endDate: string}) {
      const params = {
        release_date_time: noti.releaseDateTime,
        accounts_receive_notifications: noti.receivers.map((receiver) => {
          return {
            account_id: receiver.accountId,
            agency_name: receiver.agencyName
          }
        }),
        content_notification: noti.content,
        start_date: noti.startDate,
        end_date: noti.endDate 
      };
      try {
        let res = await notificationService.updateNotification(params);
        let error = !(res && res.status === HttpStatusCode.Ok);
        useStore().setLoading(false);
        return {
          error: error,
          errorCode: error? (res.data && res.data.message && CONST.REGEX_MSG_CODE.test(res.data.message) ? res.data.message.toLowerCase() : DEFAULT_ERR_CODE) : ''
        };
      } catch (error) {
        return {
          error: true,
          errorCode: error.data && error.data.message && CONST.REGEX_MSG_CODE.test(error.data.message) ? error.data.message.toLowerCase() : DEFAULT_ERR_CODE
        }
      }
    },
    deleteNotification: async function (releaseDateTime: string) {
      try {
        let res = await notificationService.deleteNotification(releaseDateTime);
        let error = !(res && res.status === HttpStatusCode.Ok);
        useStore().setLoading(false);
        return {
          error: error,
          errorCode: error? (res.data && res.data.message && CONST.REGEX_MSG_CODE.test(res.data.message) ? res.data.message.toLowerCase() : DEFAULT_ERR_CODE) : ''
        };
      } catch (error) {
        return {
          error: true,
          errorCode: error.data && error.data.message && CONST.REGEX_MSG_CODE.test(error.data.message) ? error.data.message.toLowerCase() : DEFAULT_ERR_CODE
        }
      }
    },
    getAccounts: async function () {
      try {
        let res = await accountService.getListAccount();
        if (res.status === HttpStatusCode.Ok) {
          return res.data.map((acc) => {
            return {
              accountId: acc.account_id,
              agencyName: acc.agency_name
            }
          });
        }
        useStore().setLoading(false);
        return [];
      } catch (error) {
        return [];
      }
    }
  }
});
