import { defineComponent, ref, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import BasicModal from '@/features/common/modal/basic-modal.vue'

export default defineComponent({
  compatConfig: { MODE: 3 },
  components: {
    BasicModal
  },
  props: {
    messageError: String,
    messageErrorOption: String
  },
  setup(props, { emit }) {
    const msgError = ref(props.messageError);
    const msgErrorOption = ref(props.messageErrorOption);
    watch(() => props.messageError, (newVal) => {
      msgError.value = newVal;
    });
    watch(() => props.messageErrorOption, (newVal) => {
      msgErrorOption.value = newVal;
    });
    const handlingOk = () => {
      msgError.value = '';
      emit('handlingOk');
    }
    return {
      t$: useI18n().t,
      handlingOk,
      msgError,
      msgErrorOption
    };
  },
});
