import { customAxios } from "@/shared/config/axios-interceptor";

const PATH_NOTIFICATION = '/notification';

export default class NotificationService {
  public async fetchAllNotification(): Promise<any> {
    try {
      const res = await customAxios.get(PATH_NOTIFICATION);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async addNotification(params: {accounts_receive_notifications: Array<any>, content_notification: string, start_date: string, end_date: string}): Promise<any> {
    try {
      const res = await customAxios.post(PATH_NOTIFICATION, params);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async updateNotification(params: {release_date_time: string, accounts_receive_notifications: Array<any>, content_notification: string, start_date: string, end_date: string}): Promise<any> {
    try {
      const res = await customAxios.put(PATH_NOTIFICATION, params);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };

  public async deleteNotification(release_date_time: string): Promise<any> {
    try {
      const res = await customAxios.delete(`${PATH_NOTIFICATION}?release_date_time=${release_date_time}`);
      return res;
    } catch (error) {
      return Promise.reject(error.response ? error.response : error);
    }
  };
}

