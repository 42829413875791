import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  compatConfig: { MODE: 3 },
  inheritAttrs: false,
  emits: ['close'],
  props: {
    id: String,
    tabindex: {
      type: Number,
      default: -1
    }
  },
  setup(props, {emit}) {
    const target = ref(null);
    
    function mouseDown(event) {
      target.value = event.target;
    }

    function mouseUp() {
      if (target.value?.classList.contains('modal')) {
        emit('close');
      }
    }

    return {
      t$: useI18n().t,
      mouseDown: mouseDown,
      mouseUp: mouseUp
    };
  }
});