export const LATLNG_FORMAT = /^[+-]?((\d+.\d{1,6})|(\d+$))/;

export const useNumberFormat = () => {

  /**
   * Format lat lng number: 6 digit after dot character
   * @param s number
   * @returns Lat Lng format
   */
  function formatLatLng (numb: any) {
    if (!numb) return;
    let matched = String(numb).match(LATLNG_FORMAT);
    if (!matched || !matched[0]) return;
    return Number(matched[0]);
  }

  return {
    formatLatLng: formatLatLng
  }
}